import React from 'react';

import AgencyAboutImg from "../../assets/images/karigar-about.jpg";

// Shape Images 
import Shape2 from "../../assets/images/shape2.svg";
import Shape3 from "../../assets/images/shape3.svg";
import Shape4 from "../../assets/images/shape4.svg"; 
import Shape5 from "../../assets/images/agency-image/agency-shape2.png"; 

const AboutUsContent = () => {
    return (
        <div className="agency-about-area">
            <div className="container-fluid">
                <div className="row align-items-center">
                    <div className="col-lg-6 col-md-12">
                        <div className="agency-about-img">
                            <img src={AgencyAboutImg} alt="About Karigar" />
                        </div>
                    </div>

                    <div className="col-lg-6 col-md-12">
                        <div className="agency-about-content">
                            <span className="sub-title">Who We Are</span>
                            <h2>Innovating Future</h2>
                            <div className="bar"></div>
                            <p></p>
                            <p>We constantly work on new IT solutions to provide an innovative and efficient service.  Our projects are completed in different ways making them more than just integration of advanced technologies. </p>
                            <p>Karigar’s greatest assets are the team, 13+ years of experience in IT business and our flexibility allowing us to do the seemingly impossible. We aim to build long standing relationships with our clients.
                            </p>

                            {/*<Link to="/about" className="btn btn-secondary">*/}
                            {/*    Discover More*/}
                            {/*</Link>*/}
                        </div>
                    </div>
                </div>
            </div>

            {/* Shape Images */}
            <div className="shape2 rotateme">
                <img src={Shape2} alt="shape" />
            </div>
            <div className="shape3">
                <img src={Shape3} alt="shape" />
            </div>
            <div className="shape6 rotateme">
                <img src={Shape4} alt="shape" />
            </div>
            <div className="shape7">
                <img src={Shape4} alt="shape" />
            </div>
            <div className="shape8 rotateme">
                <img src={Shape2} alt="shape" />
            </div>
            <div className="shape10">
                <img src={Shape5} alt="shape" />
            </div>
        </div>
    );
}

export default AboutUsContent;