import React from 'react';

import AgencyServicesImg1 from "../../assets/images/karigar-it-professionals.jpg";
import AgencyServicesImg2 from "../../assets/images/karigar-software-engineers.jpg";
import AgencyServicesImg3 from "../../assets/images/karigar-web-development.jpg";
import AgencyServicesImg4 from "../../assets/images/karigar-creative-team.jpg";
import AgencyServicesImg5 from "../../assets/images/karigar-digital-marketing.jpg";
import AgencyServicesImg6 from "../../assets/images/karigar-data-analysis.jpg";

// Shape Images
import Shape2 from "../../assets/images/shape2.svg";
import Shape3 from "../../assets/images/shape3.svg";
import Shape4 from "../../assets/images/shape4.svg";

const OurServices = () => {
    return (
        <div className="agency-services-area pt-80 pb-50">
            <div className="container">
                <div className="section-title st-fs-28">
                    <span className="sub-title">Our Solutions</span>
                    <h2>Our Services</h2>
                    <div className="bar"></div>
                    {/*<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>*/}
                </div>

                <div className="row justify-content-center">
                    <div className="col-lg-4 col-sm-6 col-md-6">
                        <div className="agency-services-box">
                            <img src={AgencyServicesImg1} alt="Karigar's IT Professionals" />

                            <div className="content">
                                <h3>
                                    IT Professionals
                                </h3>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-4 col-sm-6 col-md-6">
                        <div className="agency-services-box">
                            <img src={AgencyServicesImg2} alt="Software Engineers" />

                            <div className="content">
                                <h3>
                                    Software Engineers
                                </h3>
                            </div>
                        </div>
                    </div>
                    
                    <div className="col-lg-4 col-sm-6 col-md-6">
                        <div className="agency-services-box">
                            <img src={AgencyServicesImg3} alt="Web Development" />

                            <div className="content">
                                <h3>
                                    Web Development
                                </h3>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-4 col-sm-6 col-md-6">
                        <div className="agency-services-box">
                            <img src={AgencyServicesImg4} alt="Creative Team" />

                            <div className="content">
                                <h3>
                                    Creative Team
                                </h3>
                            </div>
                        </div>
                    </div>
                    
                    <div className="col-lg-4 col-sm-6 col-md-6">
                        <div className="agency-services-box">
                            <img src={AgencyServicesImg5} alt="Digital Marketing" />

                            <div className="content">
                                <h3>
                                    Digital Marketing
                                </h3>
                            </div>
                        </div>
                    </div>
                    
                    <div className="col-lg-4 col-sm-6 col-md-6">
                        <div className="agency-services-box">
                            <img src={AgencyServicesImg6} alt="Data Analysts" />

                            <div className="content">
                                <h3>
                                    Data Analysts
                                </h3>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Shape Images */}
            <div className="shape2 rotateme">
                <img src={Shape2} alt="shape" />
            </div>
            <div className="shape3">
                <img src={Shape3} alt="shape" />
            </div>
            <div className="shape4">
                <img src={Shape4} alt="shape" />
            </div>
            <div className="shape6 rotateme">
                <img src={Shape4} alt="shape" />
            </div>
            <div className="shape7">
                <img src={Shape4} alt="shape" />
            </div>
            <div className="shape8 rotateme">
                <img src={Shape2} alt="shape" />
            </div>
        </div>
    );
}

export default OurServices;