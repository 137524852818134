import React from "react";
import Layout from "../components/_App/layout";
import Seo from "../components/_App/seo";
import MainBanner from "../components/DigitalAgency/MainBanner";
import WhatWeOffer from '../components/DigitalAgency/WhatWeOffer';
import AboutUsContent from '../components/DigitalAgency/AboutUsContent';
import OurServices from '../components/DigitalAgency/OurServices';
import DigitalAgencyFunFacts from '../components/DigitalAgency/DigitalAgencyFunFacts';
import CTAStyleTwo from '../components/Common/CTAStyleTwo';
import Footer from "../components/_App/Footer";
import NavbarStyleSix from "../components/_App/NavbarStyleSix";

const DigitalAgency = () => (
    <Layout>
        <Seo title="Home" />
        <NavbarStyleSix />
        <MainBanner />
        <WhatWeOffer />
        <AboutUsContent />
        <OurServices />
        <DigitalAgencyFunFacts />
        <CTAStyleTwo />
        <Footer />
    </Layout>
)

export default DigitalAgency;
