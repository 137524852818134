import React from 'react';
import { Link } from "gatsby";

import AgencyIconBG from "../../assets/images/agency-image/agency-icon-bg.png";
import AgencyShape1 from "../../assets/images/agency-image/agency-shape1.png";

const WhatWeOffer = () => {
    return (
        <div className="solutions-area ptb-80">
            <div className="container">
                <div className="section-title st-fs-28">
                    <span className="sub-title">What We Offer</span>
                    <h2>Why Work With Karigar?</h2>
                    <div className="bar"></div>
                    <p>Our company offers range of solutions for SMEs around the globe.</p>
                </div>

                <div className="row">
                    <div className="col-lg-3 col-sm-6 col-md-6">
                        <div className="solutions-box">
                            <div className="icon">
                                <i className="bx bx-label"></i>
                                <img src={AgencyIconBG} alt="Karigar is flexible" />
                            </div>

                            <h3>
                                <Link to="/service-details">
                                    Great Flexibility
                                </Link>
                            </h3>
                            <p>We offer Managed services, shared services or staff augmentation with the ability to ramp up or down as your project demands.</p>

                            {/*<Link to="/service-details" className="learn-more-btn">*/}
                            {/*    <Icon.PlusCircle /> Learn More*/}
                            {/*</Link>*/}
                        </div>
                    </div>

                    <div className="col-lg-3 col-sm-6 col-md-6">
                        <div className="solutions-box">
                            <div className="icon">
                                <i className="bx bxs-watch"></i>
                                <img src={AgencyIconBG} alt="Karigar Is Global" />
                            </div>

                            <h3>
                                <Link to="/service-details">
                                    Various Time Coverage
                                </Link>
                            </h3>
                            <p>We operates in various time zones, covering 24/7 to assist you better for your modern business needs and help you grow worry free</p>

                            {/*<Link to="/service-details" className="learn-more-btn">*/}
                            {/*    <Icon.PlusCircle /> Learn More*/}
                            {/*</Link>*/}
                        </div>
                    </div>

                    <div className="col-lg-3 col-sm-6 col-md-6">
                        <div className="solutions-box">
                            <div className="icon">
                                <i className="bx bx-world"></i>
                                <img src={AgencyIconBG} alt="Karigar got Extensive Experience" />
                            </div>

                            <h3>
                                <Link to="/service-details">
                                    Extensive Experience
                                </Link>
                            </h3>
                            <p>We have been developing and managing IT projects successfully for range of clients, across the globe for more than a decade.</p>

                            {/*<Link to="/service-details" className="learn-more-btn">*/}
                            {/*    <Icon.PlusCircle /> Learn More*/}
                            {/*</Link>*/}
                        </div>
                    </div>

                    <div className="col-lg-3 col-sm-6 col-md-6">
                        <div className="solutions-box">
                            <div className="icon">
                                <i className="bx bx-check-shield"></i>
                                <img src={AgencyIconBG} alt="Karigar's great IT Background" />
                            </div>

                            <h3>
                                <Link to="/service-details">
                                    Background
                                </Link>
                            </h3>
                            <p>We are an IT company with a pedigree of your business goals. We have the right technologies, skillset, partnerships, experience and state of the art.</p>

                            {/*<Link to="/service-details" className="learn-more-btn">*/}
                            {/*    <Icon.PlusCircle /> Learn More*/}
                            {/*</Link>*/}
                        </div>
                    </div>
                </div>
            </div>
            
            <div className="shape9 slow mobile-dnone">
                <img 
                    src={AgencyShape1} 
                    className="animate__animated animate__fadeInLeft animate__delay-0.7s" 
                    alt="What Karigar Offers"
                />
            </div>
        </div>
    );
}

export default WhatWeOffer;