import React from 'react';

import MapImg from "../../assets/images/map.png";

const DigitalAgencyFunFacts = () => {
    return (
        <div className="funfacts-area ptb-80">
            <div className="container">
                <div className="section-title">
                    <h2>What We Have Accomplished</h2>
                    <div className="bar"></div>
                    <p>Our team uses their knowledge and experience gained over many years to do the impossible.
                    </p>
                </div>

                <div className="row">
                    <div className="col-lg-3 col-md-3 col-6">
                        <div className="funfact">
                            <h3>1800+</h3>
                            <p>Clients</p>
                        </div>
                    </div>

                    <div className="col-lg-3 col-md-3 col-6">
                        <div className="funfact">
                            <h3>2500+</h3>
                            <p>Projects</p>
                        </div>
                    </div>

                    <div className="col-lg-3 col-md-3 col-6">
                        <div className="funfact">
                            <h3>16000+</h3>
                            <p>GIT Pushes</p>
                        </div>
                    </div>

                    <div className="col-lg-3 col-md-3 col-6">
                        <div className="funfact">
                            <h3>∞</h3>
                            <p>Tea and Coffee</p>
                        </div>
                    </div>
                </div>

                <div className="map-bg">
                    <img src={MapImg} alt="map" />
                </div>
            </div>
        </div>
    )
}

export default DigitalAgencyFunFacts;